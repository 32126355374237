<template>
  <validation-observer
    ref="accountSaveFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="accountSaveModal"
      centered
      :title="isCreated ? 'Thêm tài khoản' : 'Cập nhật tài khoản'"
      :no-close-on-backdrop="true"
      body-class="position-static"
      @show="onShow"
      @hide="onHide"
    >
      <b-form>
        <b-form-group label-for="username">
          <template v-slot:label>
            Tài khoản <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Tài khoản"
            rules="required"
          >
            <b-form-input
              id="username"
              v-model="targetAccount.username"
              name="username"
              placeholder="Nhập tài khoản"
              :state="getElementState(errors)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <template v-if="isCreated">
          <b-form-group label-for="password">
            <template v-slot:label>
              Mật khẩu <span class="text-danger">*</span>
            </template>
            <validation-provider
              #default="{ errors }"
              name="Mật khẩu"
              vid="password"
              rules="required|min:8|password"
            >
              <b-form-input
                id="password"
                v-model="targetAccount.password"
                type="password"
                name="password"
                placeholder="Nhập mật khẩu"
                :state="getElementState(errors)"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label-for="cPassword">
            <template v-slot:label>
              Nhập lại mật khẩu <span class="text-danger">*</span>
            </template>
            <validation-provider
              #default="{ errors }"
              name="Nhập lại mật khẩu"
              rules="required|confirmed:password"
            >
              <b-form-input
                id="cPassword"
                v-model="cPassword"
                type="password"
                name="cPassword"
                placeholder="Nhập lại mật khẩu"
                :state="getElementState(errors)"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </template>
        <b-form-group label-for="role">
          <template v-slot:label>
            Vai trò <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Vai trò"
            rules="required"
          >
            <v-select
              v-model="targetAccount.roles"
              multiple
              :options="roles"
              :reduce="option => option.id"
              label="name"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
        <b-form-group label-for="status">
          <template v-slot:label>
            Trạng thái <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Trạng thái"
            rules="required"
          >
            <v-select
              v-model="targetAccount.status"
              :options="statusOptions"
              :reduce="option => option.value"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
        <b-form-group label-for="orderNo">
          <template v-slot:label>
            Thứ tự <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Thứ tự"
            rules="required"
          >
            <b-form-input
              id="orderNo"
              v-model="targetAccount.orderNo"
              name="orderNo"
              placeholder="Nhập thứ tự"
              :state="getElementState(errors)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <template>
          <b-form-group
            v-slot="{ ariaDescribedby }"
            label-for="linking"
          >
            <validation-provider
              name="linking"
              vid="linking"
            >
              <b-form-checkbox-group
                v-model="targetAccount.linkingSelected"
                :options="linkingOptions"
                :aria-describedby="ariaDescribedby"
                name="linking"
              />
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Danh sách liên kết"
            label-for="linkingId"
          >
            <validation-provider
              #default="{ errors }"
              rules="required-if:linking"
            >
              <v-select
                v-model="targetAccount.linkingId"
                label="name"
                :options="options"
                :reduce="option => option.id"
                :disabled="targetAccount.linkingSelected.length === 0"
              />
              <b-form-invalid-feedback :state="getElementState(errors)">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider>
          </b-form-group>
        </template>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-show="isCreated"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid || linkingDisabled"
            @click="onSave"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu và tiếp tục
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid || linkingDisabled"
            @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('accountSaveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>

      <b-overlay
        no-wrap
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        :show="isLoading"
      /></b-modal>
  </validation-observer>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BModal, BOverlay, BFormCheckboxGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import {
  confirmed, min, required, requiredIf,
} from '@core/utils/validations/validations'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { STATUSES } from '@/const/status'
import { getUser } from '@/auth/utils'

export default {
  name: 'AccountSave',
  components: {
    BFormGroup,
    BFormInput,
    BModal,
    BForm,
    BOverlay,
    BFormInvalidFeedback,
    BButton,
    BFormCheckboxGroup,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    account: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      targetAccount: {
        username: '',
        password: '',
        status: 1,
        orderNo: 1,
        linkingSelected: [],
        linkingId: null,
        linkAccountId: null,
        roles: [],
      },
      cPassword: '',
      linkingOptions: [
        {
          value: 1,
          text: 'Liên kết tài khoản sinh viên',
          disabled: false,
        },
        {
          value: 2,
          text: 'Liên kết tài khoản cán bộ',
          disabled: false,
        },
      ],
      options: [],
      saveDisabled: false,
      required,
      requiredIf,
      confirmed,
      min,
    }
  },
  computed: {
    ...mapGetters({
      employees: 'employee/employees',
      students: 'student/students',
      roles: 'role/roles',
    }),
    statusOptions() {
      return STATUSES
    },
    isCreated() {
      return !this.account
    },
    linkingDisabled() {
      if (this.targetAccount.linkingSelected.length === 0) return false
      return !this.targetAccount.linkingId
    },
  },
  watch: {
    'targetAccount.linkingSelected': async function (newVal) {
      if (newVal.length === 0) {
        this.linkingOptions = this.linkingOptions.map(element => ({ ...element, ...{ disabled: false } }))
        this.options = []
        this.targetAccount.linkingId = null
      } else {
        this.linkingOptions = this.linkingOptions.map(element => {
          if (newVal.includes(element.value)) return { ...element, ...{ disabled: false } }
          return { ...element, ...{ disabled: true } }
        })
        const type = newVal[0]
        this.isLoading = true
        try {
          const param = { organizationId: this.user.orgId }
          if (type === 1) await this.getStudentsNotLinkingAccount(param)
          else await this.getEmployeesNotLinkingAccount(param)
          this.options = type === 1 ? this.students : this.employees
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
  },
  methods: {
    ...mapActions({
      createAccount: 'account/createAccount',
      updateAccount: 'account/updateAccount',
      getEmployeesNotLinkingAccount: 'employee/getEmployeesNotLinkingAccount',
      getStudentsNotLinkingAccount: 'student/getStudentsNotLinkingAccount',
      getRoles: 'role/getRoles',
    }),
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async onShow() {
      await this.getRoles({ currentPage: 1, itemsPerPage: 100 })
      if (this.account) {
        this.targetAccount = { ...this.targetAccount, ...this.account }
      }
    },
    onHide() {
      this.$refs
        .accountSaveFormRef
        .reset()
      this.targetAccount = {
        username: '',
        password: '',
        status: 1,
        orderNo: 1,
        linkingSelected: [],
        linkingId: null,
        roles: [],
      }
      this.cPassword = ''
    },
    updateParams(newProps) {
      this.targetAccount = { ...this.targetAccount, ...newProps }
    },
    async onSave(type = null) {
      const valid = this.$refs
        .accountSaveFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        try {
          if (this.targetAccount.linkingSelected.length > 0) {
            this.updateParams({ linkingType: this.targetAccount.linkingSelected[0] })
          }
          const response = this.isCreated
            ? await this.createAccount(this.targetAccount)
            : await this.updateAccount(this.targetAccount)
          if (response) {
            const { isSuccessful, message } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('accountSaveModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
